import { ComponentRef, EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { ISidebarConfig } from './sidebar-config.interface';
import { FuseDrawerService } from '@fuse/components/drawer/drawer.service';
import { IDialogButton } from '@kiwid-app/kiwid-ui-lib-angular';

@Injectable({
    providedIn: 'root',
})
export class SidebarService implements OnDestroy {
    private subscriptions = new Subscription();
    private _componentRefObservable = new Subject<ComponentRef<any> | null>();
    readonly scrollEvent = new EventEmitter<Event>();

    constructor(
        private readonly fuseDrawerService: FuseDrawerService,
    ) {}

    private _clickButtonObservable = new Subject<IDialogButton>();
    private _closeObservable = new Subject<void>();

    get clickButtonObservable(): Observable<IDialogButton> {
        return this._clickButtonObservable.asObservable();
    }

    get closeObservable(): Observable<void> {
        return this._closeObservable.asObservable();
    }

    private _config = new Subject<ISidebarConfig>();

    set config(value: ISidebarConfig) {
        this._config.next(value);
    }

    get configObservable(): Observable<ISidebarConfig> {
        return this._config.asObservable();
    }

    clickButton(button: IDialogButton): void {
        this._clickButtonObservable.next(button);
    }

    ngOnDestroy(): void {
        this.resetComponentAndRef();
        this.subscriptions.unsubscribe();
    }

    close(): void {
        this._closeObservable.next();
        this.fuseDrawerService.getComponent('k-sidebar').close();
        this.resetComponentAndRef();
    }

    open(): void {
        this.fuseDrawerService.getComponent('k-sidebar').open();
        this.fuseDrawerService
            .getComponent('k-sidebar')
            .openedChanged
            .subscribe((isClose) => {
                if (!isClose) {
                    this.resetComponentAndRef();
                }
            });
    }

    componentRefObservable<TComponentRef>(): Observable<ComponentRef<TComponentRef> | null> {
        return this._componentRefObservable.asObservable();
    }

    registerComponentRef<TComponentRef>(componentRef: ComponentRef<TComponentRef>): void {
        this._componentRefObservable.next(componentRef);
    }

    registerSidebar(config: ISidebarConfig): void {
        this.config = config;
    }

    private resetComponentAndRef(): void {
        this._componentRefObservable.next(null);
    }
}
