import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { IGetAllCheck, IGetAllProductionByMonth, IGetDailyCashFlow } from "app/core/models/report/report";

@Injectable({
    providedIn: 'root',
})
export class UrlHelper {
    constructor(private readonly router: Router) {}

    goToUserAccess(): string {
        return `/clinic/user-access`;
    }

    goToOrder(): string {
        return `/production/order`;
    }

    goToOrderDetail(orderId: string): string {
        return `/production/order/detail/${orderId}/information`;
    }

    goToOrderDetailChat(orderId: string): string {
        return `/production/order/detail/${orderId}/chat`;
    }

    goToOrderDetailProduction(orderId: string): string {
        return `/production/order/detail/${orderId}/production`;
    }

    goToOrderDetailOutsource(orderId: string): string {
        return `/production/order/detail/${orderId}/outsource`;
    }

    goToInvoiceDetail(invoiceId: string): string {
        return `/invoice/detail/${invoiceId}`;
    }

    goToCustomerDetail(customerId: string): string {
        return `/people/customer/detail/${customerId}`;
    }

    goToCustomerUserDetail(customerId: string): string {
        return `/people/customer/detail/${customerId}/customer-users`;
    }

    goToEmployeeDetail(employeeId: string): string {
        return `/people/employee/detail/${employeeId}`;
    }

    goToSupplier(): string {
        return `/people/supplier`;
    }

    goToSupplierDetail(supplierId: string): string {
        return `/people/supplier/detail/${supplierId}`;
    }

    goToSettingFinancialReceivingMethod(): string {
        return '/settings/financial/receiving-method';
    }

    goToProductDetail(productId: string): string {
        return `/inventory/product/detail/${productId}`;
    }

    goToInventoryOrderDetail(inventoryOrderId: string): string {
        return `/inventory/inventory-order/detail/${inventoryOrderId}`;
    }

    goToReportOrderRating(): string {
        return '/report/order/order-rating';
    }


    goToReportFinancialDailyCashFlow(queryParams?: IGetDailyCashFlow): string {
        const url = this.router.createUrlTree(['/report/financial/daily-cash-flow'], {
            queryParams,
        });

        return decodeURIComponent(url.toString());
    }

    goToReportOrderProductionByMonth(queryParams?: IGetAllProductionByMonth): string {
        const url = this.router.createUrlTree(['/report/order/production-by-month'], {
            queryParams,
        });

        return decodeURIComponent(url.toString());
    }


    goToReportFinancialCheck(queryParams?: IGetAllCheck): string {
        const url = this.router.createUrlTree(['/report/financial/check'], {
            queryParams,
        });

        return decodeURIComponent(url.toString());
    }
}
